<template>
  <div class='py-8 lg:py-16 px-2 lg:px-0'>
    <welcome-title title='Important Notice Regarding COVID-19' />
    <p class='text-lg'>
      From October 1, 2022, 12AM (KST), the requirement for all inbound travelers to conduct a post-entry PCR test <span class='covid-highlight font-semibold' :style='themeTextColorStyle'>has been suspended</span>.
      <img 
        src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/apsr2022_covid_notice.png' 
        class='block p-8 rounded-lg max-w-3xl mx-auto w-full' />
    </p>
    <div>
      <h2 class='text-2xl font-semibold mt-8 lg:mt-16 mb-2'>PCR Tests Suspension</h2>
      <p class='my-2 text-normal lg:text-lg'><span class='covid-highlight font-semibold' :style='themeTextColorStyle'>PCR tests</span> within 1 day of arrival in South Korea is <span class='covid-highlight font-semibold' :style='themeTextColorStyle'>no longer mandatory</span>, as of <span class='covid-highlight font-semibold' :style='themeTextColorStyle'>October 1, 2022, 12AM (KST)</span>.</p>
      <p class='my-2 text-normal lg:text-lg'>Free PCR test are available at public health centers for those who wish to be tested (within 3 days of entry for both Korean & long-term foreigners).</p>
    </div>
    <div>
      <h2 class='text-2xl font-semibold mt-8 lg:mt-16 mb-2'>No Quarantine for Foreigners</h2>
      <p class='my-2 text-normal lg:text-lg'>Quarantine exemption has been extended to all overseas entrants, regardless of vaccination history, as of September 3, 2022.</p>
    </div>
    <div>
      <h2 class='text-2xl font-semibold mt-8 lg:mt-16 mb-2'>Pre-entry Quarantine (Q-code) system</h2>
      <p class='my-2 text-normal lg:text-lg'>
        It is <span class='covid-highlight font-semibold' :style='themeTextColorStyle'>strongly recommended</span> to enter in your personal information into the Q-code system before you depart for Korea. Doing so will shorten your wait time when entering South Korea, and allow for efficient follow-up.<br>
        <br>
        The required information includes: 
        <ul class='mt-2'>
          <li class='list-disc ml-4 py-0.5'>personal details</li>
          <li class='list-disc ml-4 py-0.5'>staying inforamtion</li>
          <li class='list-disc ml-4 py-0.5'>quarantine information</li>
          <li class='list-disc ml-4 py-0.5'>current health status</li>
        </ul>
      </p>
    </div>
    <div>
      <h2 class='text-2xl font-semibold mt-8 lg:mt-16 mb-2'>Quarantine Flow for Overseas Entrants</h2>
      <h3 class='text-lg uppercase mb-2 mt-8 font-semibold'>Symptomatic Entrants</h3>
      <img 
        src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/apsr2022_covid_symptomatic.png' 
        class='block rounded-lg p-2 lg:p-8 max-w-5xl w-full'
        style='background-color: #F6F6F6;' />
      <h3 class='text-lg uppercase mb-2 mt-8 font-semibold'>Asymptomatic Entrants</h3>
      <img 
        src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/apsr2022_covid_asymptomatic.png' 
        class='block rounded-lg p-2 lg:p-8 max-w-5xl w-full' 
        style='background-color: #F6F6F6;' />
    </div>
    <div>
      <h2 class='text-2xl font-semibold mt-8 lg:mt-16 mb-2'>Latest Updates on Covid-19</h2>
      <p class='my-2 text-normal lg:text-lg'>
        To check the latest updates on Covid-19, pleas visit the Central Disease Control Headquarters of Korea website.
      </p>
      <h2 class='text-2xl font-semibold mt-8 lg:mt-16 mb-2'>Face Mask Policy</h2>
      <div class='flex flex-col lg:flex-row gap-y-8 lg:gap-y-0 lg:gap-x-4'>
        <div class='w-full lg:w-1/2 flex-shrink-0 flex-grow-0'>
          <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/apsr2022_covid_mask_indoor.png' class='rounded-lg'/>
          <div class='mt-2 text-normal lg:text-lg'><span class='covid-highlight font-semibold' :style='themeTextColorStyle'>Face masks are required in public indoor spaces</span> including the congress venue, COEX.</div>
        </div>
        <div class='w-full lg:w-1/2 flex-shrink-0 flex-grow-0'>
          <img src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/apsr2022_covid_mask_outdoor.png' class='rounded-lg'/>
          <div class='mt-2 text-normal lg:text-lg'><span class='covid-highlight font-semibold' :style='themeTextColorStyle'>Face masks are not required in public outdoor spaces</span>. Chairs and speakers are only permitted to be unmasked during their session presentation.</div>
        </div>
      </div>
      <h2 class='text-2xl font-semibold mt-8 lg:mt-16 mb-2'>PCR Test Centers</h2>
      <p class='my-2 text-normal lg:text-lg'>
        There are 4 PCR test centers (hospital clinics) near the venue. By making reservations for tests ahead of time, a test confirmation will be provided in English.
      </p>
      <img 
        src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/APSR2022/apsr2022_covid_testing_centers.png' 
        class='block rounded-lg w-full'/>
      
      
    </div>
  </div>
</template>

<script>
import WelcomeTitle from '@/components/apsr2022/WelcomeTitle.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CovidNotice',
  components: {
    WelcomeTitle,
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
    ]),
  }
}
</script>
